import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useLocales } from 'src/locales';
import { khCurrency } from 'src/utils/format-number';
import { Link } from '@mui/material';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Change Password',
    linkTo: paths.dashboard.user.password,
  },
  {
    label: 'QR Code',
    linkTo: paths.dashboard.user.qrcode,
  },
  // {
  //   label: 'Profile',
  //   linkTo: paths.dashboard.user.profile,
  // },
  // {
  //   label: 'Settings',
  //   linkTo: paths.dashboard.user.account,
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();
  const { t } = useLocales();
  const { userMockInfo } = useMockedUser();

  const { logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={userMockInfo?.photoURL}
          alt={userMockInfo?.displayName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userMockInfo?.displayName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userMockInfo?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack display="flex" flexDirection={'row'} spacing={1} sx={{ p: 1 }}>
          <MenuItem sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography fontSize={'small'} fontWeight={'bold'}>
              Balance:{' '}
            </Typography>
            <Typography fontSize={'small'} fontWeight={'bold'} color={'#D71921'}>
              {khCurrency(userMockInfo?.balance ?? 0)}៛
            </Typography>
          </MenuItem>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ p: 1 }}>
          {OPTIONS?.filter((x) =>
            userMockInfo?.role !== 'member' ? x.label !== 'QR Code' : x
          ).map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {t(option.label)}
            </MenuItem>
          ))}
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack display="flex" flexDirection={'row'} spacing={1} sx={{ p: 0.5 }}>
          <Link
            display={'flex'}
            justifyContent={'start'}
            ml={'10px'}
            padding={'6px 15px'}
            color={'#fff'}
            borderRadius={'10px'}
            bgcolor={'#4b4a4a'}
            underline="none"
            href={'/download/download-app'}
          >
            <Iconify icon="flat-color-icons:android-os" width={20} color={'#388e3c'} />
            <Typography fontSize={'13px'} fontWeight={'bold'} pl={1} flexGrow={1}>
              Download APK
            </Typography>
          </Link>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
