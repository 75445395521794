import endpoints from './endpoints';
import { Types } from 'src/generated/types';
import { SWROptions, useQuerySWR } from 'src/utils/swr-instanct';

interface ResultGameTypeParams {
  is_betable?: number | null;
}

export function useQueryGameType(
  params?: ResultGameTypeParams,
  options?: SWROptions<Types.GameTypeList[], ResultGameTypeParams>
) {
  const res = useQuerySWR(endpoints.setting.gameType, params, options);
  return res;
}
