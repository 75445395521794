import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from 'src/auth/guard';
// components
import { SplashScreen } from 'src/components/loading-screen';
import AuthClassicLayout from 'src/layouts/auth/classic';

// ----------------------------------------------------------------------
// JWT
const DownloadAppPage = lazy(() => import('src/pages/download-app/download-apk'));
// ----------------------------------------------------------------------

export const publicRoutes = [
  {
    path: 'download',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'download-app',
        element: <DownloadAppPage />,
      },
    ],
  },
];
