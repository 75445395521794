// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
//
import { HEADER, NAV } from '../config-layout';
import {
  Searchbar,
  AccountPopover,
  SettingsButton,
  LanguagePopover,
  ContactsPopover,
  NotificationsPopover,
} from '../_common';
import { useCallback, useState } from 'react';
import { Box, Button, MenuItem, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import { User } from '@auth0/auth0-react';
import { useAuthContext } from 'src/auth/hooks';
import { khCurrency } from 'src/utils/format-number';
import { useMockedUser } from 'src/hooks/use-mocked-user';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const { t } = useLocales();
  const settings = useSettingsContext();
  const { user } = useAuthContext();
  const { userMockInfo } = useMockedUser();
  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const [fullscreen, setFullscreen] = useState(false);

  const onToggleFullScreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
    }
  }, []);

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {user?.user_type !== 'AGENT' ? (
          <Button variant="outlined" color="primary" onClick={onToggleFullScreen}>
            <SvgColor
              src={`/assets/icons/setting/${
                fullscreen ? 'ic_exit_full_screen' : 'ic_full_screen'
              }.svg`}
              sx={{ width: 16, height: 16, mr: 1 }}
            />
            {t(fullscreen ? 'exit_fullscreen' : 'fullscreen')}
          </Button>
        ) : (
          <Stack direction="column" justifyItems="center" alignItems="flex-end">
            {!!user && (
              <Typography
                fontSize={'small'}
                fontWeight={'bold'}
                lineHeight={'16px'}
                textTransform={'uppercase'}
                color={'#F00'}
              >
                {user.username}
              </Typography>
            )}
            <Stack direction="row">
              {/* <Typography fontSize={'small'} fontWeight={'bold'} mr={0.5}>
                Balance:
              </Typography> */}
              <Typography
                fontSize={'small'}
                fontWeight={'bold'}
                color={'#D71921'}
                lineHeight={'14px'}
              >
                {/* <Box component="img" src={'/logo/riel.png'} sx={{ width: 15, height: 15 }} />{' '} */}
                {khCurrency(userMockInfo?.balance ?? 0)}៛
              </Typography>
            </Stack>
          </Stack>
        )}
        <LanguagePopover />

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
