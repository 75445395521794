import React, { useCallback, useContext, useMemo } from 'react';
import { useQueryGameType } from 'src/api/game-type';
import { Types } from 'src/generated/types';

interface GameTypeContextProps {
  gameTypes?: Types.GameTypeList[];
  getGameTypeName: (gameType: Types.GameType) => string;
}

const GameTypeContext = React.createContext<GameTypeContextProps>({
  gameTypes: [],
  getGameTypeName: (gameType: string) => '',
});

export function GameTypeProvider(props: React.PropsWithChildren<unknown>) {
  const { data } = useQueryGameType({ is_betable: 1 }, { loadFirst: true });
  const gameTypes = useMemo(() => data?.data || [], [data?.data]);
  const getGameTypeName = useCallback(
    (gameType: Types.GameType) => gameTypes.find((f) => f.value === gameType)?.short || '',
    [gameTypes]
  );
  return (
    <GameTypeContext.Provider value={{ gameTypes, getGameTypeName }}>
      {props?.children}
    </GameTypeContext.Provider>
  );
}
export function useGameType() {
  return useContext(GameTypeContext);
}
