import { Navigate, NonIndexRouteObject, RouteObject, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { useAuthContext } from 'src/auth/hooks';
import { publicRoutes } from './public';

// ----------------------------------------------------------------------
export interface IRouteObject extends Omit<NonIndexRouteObject, 'children' | 'index'> {
  index?: boolean;
  permissions?: string[];
  children?: (IRouteObject | null)[];
}

function RouteFilter(route: IRouteObject | null, permissions: string[]): IRouteObject | null {
  const children = route?.children || [];
  const hasPermission =
    children.length === 0 &&
    (!route?.permissions || route?.permissions.filter((f) => permissions.includes(f)).length > 0);
  if (children.length > 0) {
    const newChildren = children.map((m) => RouteFilter(m, permissions)).filter((f) => !!f);
    return newChildren.length > 0 ? { ...route, children: newChildren || undefined } : null;
  }
  return hasPermission ? { ...route, children: undefined } : null;
}

export default function Router() {
  const { permissions } = useAuthContext();
  const routes: IRouteObject[] = [
    // SET INDEX PAGE WITH SKIP HOME PAGE
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },
    // Public routes
    ...publicRoutes,

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ];
  return useRoutes(routes.map((m) => RouteFilter(m, permissions)) as RouteObject[]);
}
