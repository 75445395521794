import { useEffect, useMemo, useState } from 'react';
import useMutationSWR, { SWRMutationConfiguration } from 'swr/mutation';
import axiosInstance, { axiosMutate, axiosQuery } from './axios-instanct';
import { Types } from 'src/generated/types';
import { AxiosError, AxiosResponse } from 'axios';

type SWRUriToQuery<K> = K & {
  query?: { id: any }
}
export interface SWRErrorResponse {
  errors: any;
  message: string;
}

export interface SWROptions<T, K>
  extends SWRMutationConfiguration<Types.ResponseSuccess<T>, SWRErrorResponse, string, K, any> {
  listen?: boolean;
  loadFirst?: boolean;
  method?: 'POST' | 'DELETE' | 'PUT';
}

export const useQuerySWR = <T, K = any>(url: string, params?: K, options?: SWROptions<T, K>) => {
  let args = { ...params };
  const {
    data,
    error,
    reset,
    isMutating: isLoading,
    trigger
  } = useMutationSWR<Types.ResponseSuccess<T>, SWRErrorResponse, any, K>(url, axiosQuery, options);

  const refetch = (_params?: K) => {
    args = { ...args, ..._params };
    // @ts-ignore
    trigger(args);
  };

  useEffect(() => {
    if (options && options.loadFirst && !options.listen) {
      refetch(params);
    }
  }, []);

  useEffect(() => {
    if (options?.listen) {
      refetch(params);
    }
  }, [options?.listen, url, params]);

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      reset,
      refetch,
      error,
    }),
    [data, reset, isLoading, refetch, error]
  );

  return memoizedValue;
};

export const useMutateSWR = <T, K = any>(url: string, params?: K, options?: SWROptions<T, K>) => {
  let args = { ...params };
  const {
    data,
    error,
    reset,
    isMutating: isLoading,
    trigger,
  } = useMutationSWR<Types.ResponseSuccess<T>, SWRErrorResponse, any, SWRUriToQuery<K>>(
    url,
    axiosMutate(options?.method),
    // @ts-ignore
    options
  );

  const mutate = (_params?: SWRUriToQuery<K>) => {
    args = { ...args, ..._params };
    // @ts-ignore
    trigger(args);
  };

  useEffect(() => {
    if (options && options.loadFirst) {
      // @ts-ignore
      mutate(params);
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      reset,
      mutate,
      error,
    }),
    [data, reset, isLoading, mutate, error]
  );

  return memoizedValue;
};

export interface DownloadExportFileOptions {
  fileName?: string;
  extension?: 'csv' | 'xlsx';
  onError?: (err: AxiosError) => void;
  onSuccess?: (res: AxiosResponse) => void;
}

export const useDownloadExportFile = <T>(
  url: string,
  params?: T,
  options?: DownloadExportFileOptions
) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onDownload = (requestParams?: T, requestOptions?: DownloadExportFileOptions) => {
    setLoading(true);
    const fileName = requestOptions?.fileName || options?.fileName || 'download';
    const extension = requestOptions?.extension || options?.extension || 'csv';
    return new Promise((resolve, reject) => {
      const query = Object.entries({ ...(requestParams || params || {}), fileName, extension })
        .filter((f) => f[1] != null && f[1] != undefined)
        .map((x) => `${x[0]}=${x[1]}`)
        .join('&');
      const requestUrl = `${url}${!!query ? '?' : ''}${query}`;

      axiosInstance
        .get(requestUrl, { responseType: 'blob' })
        .then(({ data: blob }) => {
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = `${fileName}.${extension}`;
          link.click();
          resolve(blob);
          if (requestOptions) {
            requestOptions.onSuccess && requestOptions.onSuccess(blob);
          } else {
            options?.onSuccess && options.onSuccess(blob);
          }
        })
        .catch((err) => {
          reject(err);
          if (requestOptions) {
            requestOptions.onError && requestOptions.onError(err);
          } else {
            options?.onError && options.onError(err);
          }
        })
        .finally(() => setLoading(false));
    });
  };

  return { loading, onDownload };
};
