import { useGameType } from 'src/contexts/game-type/game-type';
import { Types } from 'src/generated/types';
import { localStorageAvailable } from './storage-available';

export function paramCase(str: string) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '');
}

export function snakeCase(str: string) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/[^a-z0-9_]/g, '');
}
