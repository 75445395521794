import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useEffect, useMemo, useState } from 'react';
// config
import { HOST_API } from 'src/config-global';
import useMutationSWR, { SWRMutationConfiguration } from 'swr/mutation';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API, withCredentials: true });

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['Accept'] = 'application/json';
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export const defaultSwrOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

const mutateFetcher = async (url: string, { arg }: { arg: any }) => {
  const res = await axiosInstance.post(url, arg);
  return res.data;
};

const deleteFetcher = async (url: string, { arg }: { arg: any }) => {
  const id = arg.query && arg.query.id ? arg.query.id : '';
  const URL = `${url}${!!id ? '/' + id : ''}`;
  const res = await axiosInstance.delete(URL, { data: arg });
  return res.data;
};

const putFetcher = async (url: string, { arg }: { arg: any }) => {
  const id = arg.query && arg.query.id ? arg.query.id : '';
  const URL = `${url}${!!id ? '/' + id : ''}`;
  const res = await axiosInstance.put(URL, arg);
  return res.data;
};

// ----------------------------------------------------------------------

export const queryFetcher = async (url: string, { arg }: { arg: any }) => {
  const query = Object.entries(arg || {})
    .filter((f) => f[1] != null && f[1] != undefined)
    .map((x) => `${x[0]}=${x[1]}`)
    .join('&');
  const URL = `${url}${!!query ? '?' : ''}${query}`;
  const res = await axiosInstance.get(URL);
  return res.data;
};

export interface SWRErrorResponse {
  errors: any;
  message: string;
}

export interface SWRMutationOptions<T, K>
  extends SWRMutationConfiguration<T, SWRErrorResponse, string, K, any> {
  listen?: boolean;
  loadFirst?: boolean;
  method?: 'POST' | 'DELETE' | 'PUT' | 'PATCH';
}

export const useQuerySWR = <T, K = any>(
  url: string,
  params?: K,
  options?: SWRMutationOptions<T, K>
) => {
  const {
    data,
    error,
    reset,
    isMutating: isLoading,
    trigger: refetch,
  } = useMutationSWR<T, SWRErrorResponse, any, K>(url, queryFetcher, options);

  useEffect(() => {
    if (options && options.loadFirst && !options.listen) {
      // @ts-ignore
      refetch(params);
    }
  }, []);

  useEffect(() => {
    if (options?.listen) {
      // @ts-ignore
      refetch(params);
    }
  }, [options?.listen, url, params]);

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      reset,
      refetch,
      error,
    }),
    [data, reset, isLoading, refetch, error]
  );

  return memoizedValue;
};

export const useMutateSWR = <T, K = any>(
  url: string,
  params?: K,
  options?: SWRMutationOptions<T, K>
) => {
  let fetcher = mutateFetcher;
  switch (options?.method) {
    case 'DELETE':
      fetcher = deleteFetcher;
      break;
    case 'PUT':
      fetcher = putFetcher;
      break;
  }
  const {
    data,
    error,
    reset,
    isMutating: isLoading,
    trigger: mutate,
  } = useMutationSWR<T, SWRErrorResponse, any, K>(url, fetcher, options);

  useEffect(() => {
    if (options && options.loadFirst) {
      // @ts-ignore
      mutate(params);
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      reset,
      mutate,
      error,
    }),
    [data, reset, isLoading, mutate, error]
  );

  return memoizedValue;
};

export interface DownloadExportFileOptions {
  fileName?: string;
  extension?: 'csv' | 'xlsx';
  onError?: (err: AxiosError) => void;
  onSuccess?: (res: AxiosResponse) => void;
}

export const useDownloadExportFile = <T>(
  url: string,
  params?: T,
  options?: DownloadExportFileOptions
) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onDownload = (requestParams?: T, requestOptions?: DownloadExportFileOptions) => {
    setLoading(true);
    const fileName = requestOptions?.fileName || options?.fileName || 'download';
    const extension = requestOptions?.extension || options?.extension || 'csv';
    return new Promise((resolve, reject) => {
      const query = Object.entries({ ...(requestParams || params || {}), fileName, extension })
        .filter((f) => f[1] != null && f[1] != undefined)
        .map((x) => `${x[0]}=${x[1]}`)
        .join('&');
      const requestUrl = `${url}${!!query ? '?' : ''}${query}`;

      axiosInstance
        .get(requestUrl, { responseType: 'blob' })
        .then(({ data: blob }) => {
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = `${fileName}.${extension}`;
          link.click();
          resolve(blob);
          if (requestOptions) {
            requestOptions.onSuccess && requestOptions.onSuccess(blob);
          } else {
            options?.onSuccess && options.onSuccess(blob);
          }
        })
        .catch((err) => {
          reject(err);
          if (requestOptions) {
            requestOptions.onError && requestOptions.onError(err);
          } else {
            options?.onError && options.onError(err);
          }
        })
        .finally(() => setLoading(false));
    });
  };

  return { loading, onDownload };
};

export const endpoints = {
  csrf: '/sanctum/csrf-cookie',
  auth: {
    me: '/api/me',
    login: '/api/login',
    logout: '/api/logout',
  },
  setting: {
    zone: '/api/setting/zone',
    resultTime: '/api/setting/result/times',
    holiday: '/setting/result/times/holiday',
  },
  result: {
    root: (id: any) => `/api/result/${String(id)}`,
    time: '/api/result/time',
    releaseVn: '/api/result/release/vn',
    release5d: '/api/result/release/5d',
    releaseJackpot: '/api/result/release/jackpot',
  },
  ticket: {
    load: '/api/ticket/load',
    bet: '/api/ticket/bet',
    edit: '/api/ticket/edit',
    delete: '/api/ticket/delete',
    editForm: '/api/ticket/edit/form',
    addBreakNumber: '/api/ticket/breaknumber/add',
    loadBreakNumber: '/api/ticket/breaknumber/load',
  },
  report: {
    dailyBet: '/api/report/daily/bet',
    dailyDetail: '/api/report/daily/detail',
    exportDailyBet: '/api/report/daily/bet/export',
    exportDailyDetail: '/api/report/daily/detail/export',
  },
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  role: {
    get: '/api/role/get',
  },
  user: {
    admin: '/api/user/admin',
    resetPassword: '/api/user/admin/reset-password',

    dealer: '/api/user/dealer',
    updatePassword: '/api/user/password/update',
  },
  location: 'api/location',
};
