import { memo, useCallback, useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import { NavSectionHorizontal } from 'src/components/nav-section';
//
import { useSettingsContext } from 'src/components/settings';
import { useResponsive } from 'src/hooks/use-responsive';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import { AccountPopover, HeaderShadow, LanguagePopover } from '../_common';
import { useNavData } from './config-navigation';
import { HEADER, NAV } from '../config-layout';
import SvgColor from 'src/components/svg-color/svg-color';
import { useAuthContext } from 'src/auth/hooks';
import { useLocales } from 'src/locales';
import { khCurrency } from 'src/utils/format-number';

// ----------------------------------------------------------------------

function NavHorizontal() {
  const theme = useTheme();
  const { t } = useLocales();
  const { user } = useAuthContext();

  const navData = useNavData();

  const settings = useSettingsContext();

  const { userMockInfo } = useMockedUser();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const [fullscreen, setFullscreen] = useState(false);

  const onToggleFullScreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }, []);

  const onFullScreen = useCallback((e: any) => {
    setFullscreen(!!document.fullscreenElement);
  }, []);

  useEffect(() => {
    document.addEventListener('fullscreenchange', onFullScreen);
    return () => document.removeEventListener('fullscreenchange', onFullScreen);
  }, []);

  return (
    <AppBar component="nav">
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          transition: theme.transitions.create(['height'], {
            duration: theme.transitions.duration.shorter,
          }),
          ...(lgUp && {
            width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
            height: HEADER.H_DESKTOP,
            ...(offsetTop && {
              height: HEADER.H_DESKTOP_OFFSET,
            }),
            ...(isNavHorizontal && {
              width: 1,
              bgcolor: 'background.default',
              height: HEADER.H_DESKTOP_OFFSET,
              borderBottom: `dashed 1px ${theme.palette.divider}`,
            }),
            ...(isNavMini && {
              width: `calc(100% - ${NAV.W_MINI + 1}px)`,
            }),
          }),
        }}
      >
        <NavSectionHorizontal
          data={navData}
          config={{
            currentRole: user?.role || 'admin',
          }}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={{ xs: 0.5, sm: 1 }}
        >
          {user?.user_type !== 'AGENT' ? (
            <Button variant="outlined" color="primary" onClick={onToggleFullScreen}>
              <SvgColor
                src={`/assets/icons/setting/${
                  fullscreen ? 'ic_exit_full_screen' : 'ic_full_screen'
                }.svg`}
                sx={{ width: 16, height: 16, mr: 1 }}
              />
              {t(fullscreen ? 'exit_fullscreen' : 'fullscreen')}
            </Button>
          ) : (
            <Stack direction="column" justifyItems="center" alignItems="flex-end">
              {!!user && (
                <Typography
                  fontWeight={'bold'}
                  fontSize={'15px'}
                  lineHeight={'15px'}
                  textTransform={'uppercase'}
                  color={'#F00'}
                >
                  {user.username}
                </Typography>
              )}
              <Stack direction="row">
                <Typography
                  fontSize={'small'}
                  fontWeight={'bold'}
                  color={'#D71921'}
                  lineHeight={'14px'}
                >
                  {/* <Box component="img" src={'/logo/riel.png'} sx={{ width: 14, height: 14 }} />{' '} */}
                  {khCurrency(userMockInfo?.balance ?? 0)}៛
                </Typography>
              </Stack>
            </Stack>
          )}
          <Stack display={'flex'} flexDirection={'column'}>
            <Stack display={'flex'} flexDirection={'row'}>
              <LanguagePopover />
              <AccountPopover />
            </Stack>
          </Stack>
        </Stack>
      </Toolbar>

      <HeaderShadow />
    </AppBar>
  );
}

export default memo(NavHorizontal);
