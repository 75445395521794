export * from 'notistack';
import { useSnackbar } from 'notistack';

function useSnackbarResponse () {
  const { enqueueSnackbar } = useSnackbar();

  const onSuccessNotify = (message: string, timeout: number = 1000) => {
    enqueueSnackbar(message, { variant: 'success', autoHideDuration: timeout });
  }

  const onErrorNotify = (message: string, timeout: number = 1000) => {
    enqueueSnackbar(message, { variant: 'error', autoHideDuration: timeout });
  }
  return { onErrorNotify, onSuccessNotify };
}

export { useSnackbar, useSnackbarResponse };
export { default as SnackbarProvider } from './snackbar-provider';
